import React from 'react';

import './index.scss';
import styles from './index.module.scss';
import { Tabs } from 'antd';
import PayrollOverview from './PayrollOverview';
import PayrollHistory from './PayrollHistory';

const PayrollComponent = () => {
	const [activeKey, setActiveKey] = React.useState('payrollOverview');
	const changeTab = (key: any) => {
		setActiveKey(key);
	};

	let tabItems = [
		{
			key: 'payrollOverview',
			label: 'Payroll Overview',
			children: <PayrollOverview />,
		},
		{
			key: 'payrollHistory',
			label: 'Payroll History',
			children: <PayrollHistory/>,
		},
	];

	return (
		<div className={styles['payroll-components']}>
			<p>Payroll</p>

			<div className="payroll-components">
				<Tabs
					defaultActiveKey={activeKey}
					activeKey={activeKey}
					items={tabItems}
					onChange={(key) => changeTab(key)}
					style={{
						fontSize: '18px',
						marginBottom: '40px',
					}}
				/>
			</div>
		</div>
	);
};

export default PayrollComponent;

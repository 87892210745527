import { createAsyncThunk } from '@reduxjs/toolkit';
import { payrollApi } from 'Api/payroll';

export const fetchCompanyWizard = createAsyncThunk(
	'CompanyWizard',
	async (_, { rejectWithValue }) => {
		try {
			const response = await payrollApi.getCompletedPayrollModules();
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);

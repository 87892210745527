import { createAsyncThunk } from '@reduxjs/toolkit';
import { payPeriodApi } from 'Api/payPeriod';

export const fetchPayPeriodAction = createAsyncThunk(
	'payPeriod',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await payPeriodApi.getPayPeriodOptions(params);
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);

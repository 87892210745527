import React, { useState } from 'react';
import { Table, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import styles from './index.module.scss';
import './index.scss';

type Props = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	isLoading: boolean;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void; // Dynamic data passed as props
};

const TotalDeductionTable: React.FC<Props> = (props: Props) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		isLoading,

		tableChangeHandler,
	} = props;
	const [showEmployer, setShowEmployer] = useState(false);
	const dummyData = [
		{
			id: '1',
			employee: 'John Doe',
			paye: 1200,
			nhimaEmployee: 200,
			nhimaEmployer: 150,
			napsaEmployee: 100,
			napsaEmployer: 75,
			midMonth: 500,
			cashLoans: 300,
			houseLoan: 400,
			medicalFund: 50,
			unionContribution: 60,
			mealsDeduction: 25,
			total: 2960,
		},
		{
			id: '2',
			employee: 'Jane Smith',
			paye: 1100,
			nhimaEmployee: 180,
			nhimaEmployer: 140,
			napsaEmployee: 90,
			napsaEmployer: 70,
			midMonth: 450,
			cashLoans: 350,
			houseLoan: 380,
			medicalFund: 60,
			unionContribution: 50,
			mealsDeduction: 30,
			total: 2900,
		},
		{
			id: '3',
			employee: 'Michael Brown',
			paye: 1300,
			nhimaEmployee: 210,
			nhimaEmployer: 160,
			napsaEmployee: 110,
			napsaEmployer: 85,
			midMonth: 550,
			cashLoans: 310,
			houseLoan: 390,
			medicalFund: 55,
			unionContribution: 65,
			mealsDeduction: 20,
			total: 3145,
		},
	];
	const columns = [
		{
			title: '',
			children: [
				{
					title: 'Employees',
					dataIndex: 'employee',
					key: 'employee',
					width: 150,
					className: 'children-normal-font-weight',
				},
			],
		},
		{
			title: (
				<div>
					Statutory components
					<Switch
						checkedChildren="Show employers"
						unCheckedChildren="Hide employers"
						onChange={(checked) => setShowEmployer(checked)}
						style={{ marginLeft: 10 }}
					/>
				</div>
			),
			children: [
				{
					title: 'PAYE',
					dataIndex: 'paye',
					key: 'paye',
					width: 100,
					className: 'children-normal-font-weight',
				},
				{
					title: 'NHIMA',
					className: 'children-normal-font-weight',

					children: [
						{
							title: 'Employees',
							dataIndex: 'nhimaEmployee',
							key: 'nhimaEmployee',
							width: 100,
							className: 'children-normal-font-weight',
						},
						...(showEmployer
							? [
									{
										title: 'Employer',
										dataIndex: 'nhimaEmployer',
										key: 'nhimaEmployer',
										width: 100,
										className:
											'children-normal-font-weight',
									},
							  ]
							: []),
					],
				},
				{
					title: 'NAPSA',
					className: 'children-normal-font-weight',

					children: [
						{
							title: 'Employees',
							dataIndex: 'napsaEmployee',
							key: 'napsaEmployee',
							width: 100,
							className: 'children-normal-font-weight',
						},
						...(showEmployer
							? [
									{
										title: 'Employer',
										dataIndex: 'napsaEmployer',
										key: 'napsaEmployer',
										width: 100,
										className:
											'children-normal-font-weight',
									},
							  ]
							: []),
					],
				},
			],
		},
		{
			title: 'Loans',
			children: [
				{
					title: 'Mid month',
					dataIndex: 'midMonth',
					key: 'midMonth',
					width: 100,
					className: 'children-normal-font-weight',
				},
				{
					title: 'Cash loans',
					dataIndex: 'cashLoans',
					key: 'cashLoans',
					width: 100,
					className: 'children-normal-font-weight',
				},
				{
					title: 'House loan',
					dataIndex: 'houseLoan',
					key: 'houseLoan',
					width: 100,
					className: 'children-normal-font-weight',
				},
			],
		},
		{
			title: 'Funds and contributions',
			children: [
				{
					title: 'Medical fund',
					dataIndex: 'medicalFund',
					key: 'medicalFund',
					width: 100,
					className: 'children-normal-font-weight',
				},
				{
					title: 'Union contribution',
					dataIndex: 'unionContribution',
					key: 'unionContribution',
					width: 100,
					className: 'children-normal-font-weight',
				},
			],
		},
		{
			title: 'Other Deductions',
			children: [
				{
					title: 'Meals/Sundry Deductions',
					dataIndex: 'mealsDeduction',
					key: 'mealsDeduction',
					className: 'children-normal-font-weight',
					width: 100,
				},
			],
		},
		{
			title: 'Total',
			children: [
				{ title: '', dataIndex: 'total', key: 'total', width: 100 },
			],
		},
	];

	return (
		<>
			<div className={styles['totalDeduction-dynamic-table']}>
				<Table
					columns={columns}
					dataSource={dummyData}
					bordered
					scroll={{ x: 'max-content' }}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,
						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				/>
			</div>
		</>
	);
};

export default TotalDeductionTable;

import { postApi, getApi } from 'apis';
const createPayPeriod = async (data: any) => {
	return await postApi('/payPeriod/create', data);
};

const getLatestPayPeriod = async () => {
	return await getApi('/payPeriod/latest');
};

const getAllPayPeriods = async (params: any) => {
	return await getApi(`/payPeriod/`, params);
};

const getPayPeriodOptions = async (params: any) => {
	return await getApi(`/payPeriod/options`,params);
};

export const payPeriodApi = {
	createPayPeriod,
	getLatestPayPeriod,
	getAllPayPeriods,
	getPayPeriodOptions,
};

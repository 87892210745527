import { Button, Col, Form, Radio, Row, Select } from 'antd';
import DatePickerField from 'components/Global/DatePicker';
import { useState } from 'react';
import { PayGroupImage } from 'utils/svgs';
import styles from './index.module.scss';
import GlobalPayPeriod from 'components/Global/PayPeriod';
import SelectDropdown from 'components/Global/SelectDropdown';

type Props = {
	selectedYear: string;
	selectedPayPeriod: string;
	selectedPayGroup: any;
	handlePayPeriod: (value: string) => void;
	handleSelectedYear: (number: string) => void;
	handlePayGroup: (number: any) => void;
	payGroupOptions: any;
	remainingEmployees: any;
	addEmployee: (id: string) => void;
	removeEmployee: (id: string) => void;
};

const PayGroupSetupComponent = (props: Props) => {
	const {
		selectedPayPeriod,
		selectedYear,
		handlePayPeriod,
		handleSelectedYear,
		payGroupOptions,
		selectedPayGroup,
		handlePayGroup,
		remainingEmployees,
		addEmployee,
		removeEmployee,
	} = props;

	const [radioValue, setRadioValue] = useState('employee');

	const handleRadioChange = (e: any) => {
		setRadioValue(e.target.value);
	};

	return (
		<>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					autoComplete="off"
				>
					{/* First Row */}
					<Row>
						<Col span={15}>
							<Row
								style={{
									paddingRight: '100px',
									paddingLeft: '30px',
								}}
							>
								<Col span={24} style={{ marginBottom: '15px' }}>
									<GlobalPayPeriod
										selectedYear={selectedYear}
										selectedPayPeriod={selectedPayPeriod}
										handlePayPeriod={(value) =>
											handlePayPeriod(value)
										}
										handleSelectedYear={(value) =>
											handleSelectedYear(value)
										}
										isRow={true}
									/>
								</Col>
								<Col span={12}>
									<SelectDropdown
										placeholder="Pay Group"
										options={payGroupOptions}
										value={selectedPayGroup}
										// disabled={isLoading}
										// loading={isLoading}
										onChange={(value) => {
											console.log('VAlue: ', value);
											handlePayGroup(value);
										}}
										size="large"
										required={true}
										helperText="Pay group name is required"
										label="Pay Group"
										isError={false}
										style={{
											paddingRight: '25px',
										}}
										mode="multiple"
									/>
								</Col>
								<Col span={12}>
									{/* <DatePickerField
										name={'dateFilter'}
										value={payMonth}
										required={false}
										picker="date"
										isError={false}
										onChange={handleDatePicker}
										label="Pay Date"
										style={{
											paddingLeft: '25px',
										}}
									/> */}
								</Col>
								<Col
									span={12}
									style={{
										marginTop: '20px',
									}}
								>
									<Form.Item>
										<Radio.Group
											onChange={handleRadioChange}
											value={radioValue}
										>
											<Radio
												value="employee"
												checked={true}
											>
												Remaining employees
											</Radio>
										</Radio.Group>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								{remainingEmployees &&
									remainingEmployees.length > 0 && (
										<Col
											span={12}
											className={styles['employee-list']}
										>
											<div
												className={
													styles['employee-header']
												}
											>
												Employees
											</div>
											{remainingEmployees.map(
												(emp: any) => {
													return (
														<div
															style={{
																display: 'flex',
															}}
															className={
																styles[
																	'employee-item'
																]
															}
														>
															<div>
																<span>
																	{emp.label}
																</span>{' '}
																|{' '}
																<span
																	style={{
																		color: '#584495',
																	}}
																>
																	{
																		emp.employeeId
																	}
																</span>
															</div>

															{emp.isAdded ? (
																<Button
																	className={
																		styles[
																			'employee-add-button'
																		]
																	}
																	onClick={() =>
																		removeEmployee(
																			emp.value
																		)
																	}
																>
																	Remove
																</Button>
															) : (
																<Button
																	className={
																		styles[
																			'employee-add-button'
																		]
																	}
																	onClick={() =>
																		addEmployee(
																			emp.value
																		)
																	}
																>
																	Add
																</Button>
															)}
														</div>
													);
												}
											)}
										</Col>
									)}
							</Row>
						</Col>

						<Col span={9}>
							<PayGroupImage />
						</Col>
					</Row>
				</Form>
			</div>
		</>
	);
};
export default PayGroupSetupComponent;

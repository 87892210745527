import { LoadingOutlined } from '@ant-design/icons';
import { employeeApi } from 'Api/employee';
import { Button, Checkbox, Col, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { EarningsModalProps } from './type';

const EarningsModal: React.FC<EarningsModalProps> = ({
	onDataChange,
	onLoadingChange,
	year,
	month,
	employeeId,
	activeTab,
	selectedPayPeriod,
}) => {
	const [data, setData] = useState<any>();
	const [earningsData, setEarningsData] = useState<any>();
	const [showAll, setShowAll] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const updateData = (newData: any) => {
		setEarningsData(
			showAll ? newData?.filter((item: any) => item.isDefault) : newData
		);
		onDataChange(newData);
	};

	const handleChange = (index: string, value: string | boolean) => {
		let newData = [...data];
		if (typeof value === 'boolean') {
			newData = data.map((item: any) => {
				if (item.id === index) {
					return { ...item, isChecked: value };
				}
				return item;
			});
		} else {
			newData = data.map((item: any) => {
				if (item.id === index) {
					return {
						...item,
						amount: isNaN(parseFloat(value))
							? parseFloat('0.00')
							: value,
					};
				}
				return item;
			});
		}
		setData(newData);
		updateData(newData);
	};

	const handleBlur = (index: number) => {
		let newData = [...data];
		newData = data.map((item: any) => {
			if (item.id === index) {
				return {
					...item,
					amount:
						isNaN(item.amount) || item.amount < 0
							? '0.00'
							: parseFloat(item.amount).toFixed(2),
				};
			}
			return item;
		});
		setData(newData);
		updateData(newData);
	};

	useEffect(() => {
		onDataChange(data);
	}, [data]);
	useEffect(() => {
		onLoadingChange(isLoading);
	}, [isLoading]);

	useEffect(() => {
		if (activeTab === 'earnings') {
			fetchEmployeeEarnings();
		}
	}, [selectedPayPeriod, activeTab]);

	const fetchEmployeeEarnings = async () => {
		setIsLoading(true);
		try {
			// const selectedYear = dayjs(year).year();
			const apiData = await employeeApi.getEmployeeEarnings({
				payPeriodId: selectedPayPeriod,
				employeeId,
			});
			if (apiData?.data?.data?.length > 0) {
				setData(apiData.data.data);
			}
		} catch (error: any) {
			toastText(error.response?.data?.message, 'error');
		}
		setIsLoading(false);
	};

	const renderItems = (items: any) =>
		items?.map((item: any) => (
			<Col key={item.id} span={12}>
				<div className={styles.earningsFields}>
					<div className="checkbox">
						<Checkbox
							checked={item.isChecked}
							onChange={() =>
								handleChange(item?.id, !item.isChecked)
							}
						/>
					</div>
					<label className={styles.label}>
						{item?.description.toUpperCase()}
					</label>
					<Input
						value={item?.amount ?? 0}
						onChange={(e) => handleChange(item?.id, e.target.value)}
						onBlur={() => handleBlur(item?.id)}
						type="number"
						suffix={'K'}
						disabled={item.isDisable}
						className={styles.input}
					/>
					<span className={styles.currency}>{item.currency}</span>
				</div>
			</Col>
		));

	const toggleShowAll = () => {
		setShowAll(!showAll);
	};

	useEffect(() => {
		setEarningsData(
			showAll ? data?.filter((item: any) => item.isDefault) : data
		);
	}, [showAll, data]);

	const defaultItems = earningsData?.filter((item: any) => item.isDefault);
	const nonDefaultItems = earningsData?.filter(
		(item: any) => !item.isDefault
	);

	return (
		<div className="earningsFields">
			{isLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: 'auto',
					}}
				>
					<LoadingOutlined
						style={{ fontSize: '100px', color: '#584495' }}
					/>
				</div>
			) : (
				<>
					<div className={styles.earningItems}>
						<Row gutter={12}>{renderItems(defaultItems)}</Row>
						<Row gutter={12}>
							{!showAll && (
								<hr
									className="color-purple"
									style={{
										width: '100vw',
										marginBottom: '20px',
									}}
								/>
							)}
							{renderItems(nonDefaultItems)}
						</Row>
						<Row>
							<Col span={23}>
								<p style={{ float: 'right' }}>
									<Button
										style={{ border: 'none' }}
										className={styles.showMoreLink}
										onClick={toggleShowAll}
									>
										{showAll ? 'Show More' : 'Show Less'}
									</Button>
								</p>
							</Col>
						</Row>
					</div>
				</>
			)}
		</div>
	);
};

export default EarningsModal;

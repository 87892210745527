import React, { useEffect, useState } from 'react';
import TableComponent from './Table';
import GlobalStatutoryHeader from '../GlobalHeader';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { toastText } from 'utils/utils';
import CalculateCompensationModal from './CalculateCompensationModal';

import { workersCompensationApi } from 'Api/workersCompensation';
import UploadRecord from './UploadRecord';

const btnText = 'Compensation';

const CompensationCalculateComponent = () => {
	const [extraDetailsPopover, setExtraDetailsPopover] = useState(false);
	const [addCommunicationModal, setAddCommunicationModal] = useState(false);
	const [calculateModalOpen, setCalculateModalOpen] = useState(false);
	const [informationModal, setInformationModal] = useState(false);
	const [uploadRecordModal, setUploadRecordModal] = useState(false);

	const [napsaInformationCurrentPage, setNapsaInformationCurrentPage] =
		useState(1);

	const [
		calculateCompensationCurrentPage,
		setCalculateCompensationCurrentPage,
	] = useState(1);
	const [calculateCompensationPageSize, setCalculateCompensationPageSize] =
		useState(10);	
	const [
		calculateCompensationTotalRecords,
		setCalculateCompensationTotalRecords,
	] = useState(0);
	const [calculateNapsaSortField, setCalculateNapsaSortField] = useState('');
	const [calculateNapsaSortOrder, setCalculateNapsaSortOrder] = useState('');
	const [calculateCompensationIsLoading, setCalculateCompensationIsLoading] =
		useState(false);
	const [calculateCompensationData, setCalculateCompensationData] =
		useState<any>([]);

	const [compensationRecordId, setNapsaRecordId] = useState<any>(null);

	const openUploadRecordModal = (napsaRecordId: string) => {
		setUploadRecordModal(true);
		setNapsaRecordId(napsaRecordId);
	};

	const toggleExtraDetails = () => {
		setExtraDetailsPopover(!extraDetailsPopover);
	};


	const tableChangeHandlerForCalculateCompensation = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setCalculateCompensationTotalRecords(pagination.total);
		setCalculateCompensationCurrentPage(pagination.current);
		setCalculateCompensationPageSize(pagination.pageSize);
		setCalculateNapsaSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setCalculateNapsaSortField(sorter.field);
	};

	const openAddCommunicationModal = () => {
		setAddCommunicationModal(true);
		setExtraDetailsPopover(false);
	};

	const closeAllModal = () => {
		setCalculateModalOpen(false);
		setAddCommunicationModal(false);
		setInformationModal(false);
		setExtraDetailsPopover(false);
		setUploadRecordModal(false);
		setNapsaRecordId(null);
	};

	const openInformationModal = () => {
		setInformationModal(true);
		setExtraDetailsPopover(false);
	};

	const openCalculateModal = () => {
		setCalculateModalOpen(true);
		setExtraDetailsPopover(false);
	};



	async function fetchCompensationReportDetails() {
		try {
			const query = {
				page: napsaInformationCurrentPage,
				sortBy: calculateNapsaSortField,
				sortOrder: calculateNapsaSortOrder,
				pageSize: calculateCompensationPageSize,
			};
			setCalculateCompensationIsLoading(true);
			const allData = await workersCompensationApi.getCompensationReports(
				query
			);

			setCalculateCompensationData(allData.data.data);

			setCalculateCompensationTotalRecords(allData.data.total);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching report Details',
					'error'
				);
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setCalculateCompensationIsLoading(false);
		}
	}

	useEffect(() => {
		fetchCompensationReportDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		calculateCompensationPageSize,
		calculateCompensationCurrentPage,
		calculateNapsaSortOrder,
		calculateNapsaSortField,
	]);
	useEffect(() => {
		closeAllModal();
	}, []);
	return (
		<>
			<GlobalStatutoryHeader
				btnText={btnText}
				openCalculateModal={openCalculateModal}
				openInformationModal={openInformationModal}
				openAddCommunicationModal={openAddCommunicationModal}
				extraDetailsPopover={extraDetailsPopover}
				toggleExtraDetails={toggleExtraDetails}
				showExtraDetails={false}
			/>

			<TableComponent
				calculateCompensationCurrentPage={
					calculateCompensationCurrentPage
				}
				calculateCompensationPageSize={calculateCompensationPageSize}
				calculateCompensationTotalRecords={
					calculateCompensationTotalRecords
				}
				calculateCompensationIsLoading={calculateCompensationIsLoading}
				calculateCompensationData={calculateCompensationData}
				tableChangeHandler={tableChangeHandlerForCalculateCompensation}
				openUploadRecordModal={openUploadRecordModal}
			/>

			<CalculateCompensationModal
				open={calculateModalOpen}
				cancelCalculateModal={closeAllModal}
				fetchCompensationReportDetails={fetchCompensationReportDetails}
			/>

				<UploadRecord
				open={uploadRecordModal}
				closeUploadRecordModal={closeAllModal}
				fetchCompensationReportDetails={fetchCompensationReportDetails}
				compensationRecordId={compensationRecordId}
			/>

		</>
	);
};

export default CompensationCalculateComponent;

import { Table } from 'antd';
import React, { useState } from 'react';
import styles from './index.module.scss';
import './index.scss';

type Props = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	isLoading: boolean;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void; // Dynamic data passed as props
};

const TotalEarningTable: React.FC<Props> = (props: Props) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		isLoading,
		tableChangeHandler,
	} = props;

	const [showEmployer, setShowEmployer] = useState(false);
	const dummyData = [
		{
			id: 1,
			employee: 'John Doe',
			housingAllowance: 500,
			transportAllowance: 200,
			gratuityPay: 300,
			shiftDifferentialPay: 150,
			houseRent: 1000,
			weekDaysOvertime: 120,
			sundayOvertime: 200,
			lunchAllowance: 80,
			seventhDayOvertime: 150,
			leavePay: 500,
			total: 3200,
		},
		{
			id: 2,
			employee: 'Jane Smith',
			housingAllowance: 400,
			transportAllowance: 150,
			gratuityPay: 250,
			shiftDifferentialPay: 120,
			houseRent: 900,
			weekDaysOvertime: 100,
			sundayOvertime: 180,
			lunchAllowance: 70,
			seventhDayOvertime: 140,
			leavePay: 450,
			total: 2830,
		},
		{
			id: 3,
			employee: 'Robert Johnson',
			housingAllowance: 600,
			transportAllowance: 220,
			gratuityPay: 320,
			shiftDifferentialPay: 160,
			houseRent: 1100,
			weekDaysOvertime: 140,
			sundayOvertime: 210,
			lunchAllowance: 85,
			seventhDayOvertime: 170,
			leavePay: 550,
			total: 3535,
		},
	];
	const columns = [
		{
			title: '',
			children: [
				{
					title: 'Employees',
					dataIndex: 'employee',
					key: 'employee',
					width: 150,
					className: 'children-normal-font-weight',
				},
			],
		},

		{
			title: 'Allowance',
			children: [
				{
					title: 'Housing Allowance ',
					dataIndex: 'housingAllowance',
					key: 'housingAllowance',
					className: 'children-normal-font-weight',
					width: 100,
				},
				{
					title: 'Transport Allowance ',
					dataIndex: 'transportAllowance',
					key: 'transportAllowance',
					className: 'children-normal-font-weight',
					width: 100,
				},
				{
					title: 'Gratuity Pay',
					dataIndex: 'gratuityPay',
					key: 'gratuityPay',
					className: 'children-normal-font-weight',
					width: 100,
				},
				{
					title: 'Shift Differential Pay',
					dataIndex: 'shiftDifferentialPay',
					key: 'shiftDifferentialPay',
					className: 'children-normal-font-weight',
					width: 100,
				},
				{
					title: 'House Rent',
					dataIndex: 'houseRent',
					key: 'houseRent',
					className: 'children-normal-font-weight',
					width: 100,
				},
			],
		},

		{
			title: 'Overtime',
			children: [
				{
					title: 'Weekdays Overtime',
					dataIndex: 'weekDaysOvertime',
					key: 'weekDaysOvertime',
					className: 'children-normal-font-weight',
					width: 100,
				},
				{
					title: 'Sunday Overtime',
					dataIndex: 'sundayOvertime',
					key: 'sundayOvertime',
					className: 'children-normal-font-weight',
					width: 100,
				},
				{
					title: 'Lunch Allowance',
					dataIndex: 'lunchAllowance',
					key: 'lunchAllowance',
					className: 'children-normal-font-weight',
					width: 100,
				},
				{
					title: 'Seventh day Overtime',
					dataIndex: 'seventhDayOvertime',
					key: 'seventhDayOvertime',
					className: 'children-normal-font-weight',
					width: 100,
				},
			],
		},

		{
			title: 'Leave Pay',
			children: [
				{
					title: 'Leave Pay',
					dataIndex: 'leavePay',
					key: 'leavePay',
					className: 'children-normal-font-weight',
					width: 100,
				},
			],
		},
		{
			title: 'Total',
			children: [
				{ title: '', dataIndex: 'total', key: 'total', width: 100 },
			],
		},
	];

	return (
		<>
			<div className={styles['totalEarning-dynamic-table']}>
				<Table
					columns={columns}
					dataSource={dummyData}
					bordered
					scroll={{ x: 'max-content' }}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,
						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				/>
			</div>
		</>
	);
};

export default TotalEarningTable;

import { SearchOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';

type Props = {
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	dateValue: any;
	handleDatePicker: any;
	StatusFilter: string;
	handleStatusFilter: (value: string) => void;
	button: any;
};

const PayrollHeader = (props: Props) => {
	const permissionContext = useContext(PermissionContext);
	// const isAdd =
	// 	permissionContext.allowedPermissions.includes('Add_P');
	const {
		searchText,
		handleSearch,
		handleSearchApi,
		handleDatePicker,
		dateValue,
		StatusFilter,
		handleStatusFilter,
		button,
	} = props;

	return (
		<div className={styles['payrollOverview-header']}>
			<Space>
				<SearchComponent
					className={styles['payrollOverview-header-item']}
					placeHolder="Search here..."
					suffixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchText}
					size="large"
				/>
				<DatePickerField
					name={'dateFilter'}
					value={dateValue}
					required={false}
					picker="month"
					isError={false}
					onChange={handleDatePicker}
				/>
				<Select
					className={styles['payrollOverview-header-item']}
					value={StatusFilter}
					options={[
						{ label: 'All', value: '' },
						{ label: 'Paid Leave', value: 'PAID' },
						{
							label: 'Unpaid Leave',
							value: 'UNPAID',
						},
					]}
					onChange={(value) => {
						handleStatusFilter(value);
					}}
					size="large"
					placeholder="Select by Leave Type"
				/>
			</Space>
			{<Buttons buttons={button} />}
		</div>
	);
};

export default PayrollHeader;

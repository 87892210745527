import { getApi } from 'apis';

const getCompanyWizard = async () => {
	return await getApi('/payroll/payrollSettingWizard');
};

const getCompletedPayrollModules = async () => {
	return await getApi('/payroll/complete-payrollModules');
};

const getRemainingPayGroup = async (query: { payPeriodId: string }) => {
	return await getApi('/payroll/remaining-payGroup', query);
};

const getRemainingEmployeeList = async (query: { payPeriodId: string }) => {
	return await getApi('/payroll/remaining-employeeList', query);
};

const payGroupSetup = async () => {
	return await getApi('/payroll/payGroup-setup');
};

const getAllPayrolls = async (params: any) => {
	return await getApi('/payroll', params);
};

export const payrollApi = {
	getCompanyWizard,
	getRemainingPayGroup,
	getRemainingEmployeeList,
	payGroupSetup,
	getCompletedPayrollModules,
	getAllPayrolls,
};

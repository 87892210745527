import { salaryApi } from 'Api/salaryDetails';
import { Col, Modal, Row } from 'antd';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import InputNumberField from 'components/Global/InputNumberField';
import GlobalPayPeriod from 'components/Global/PayPeriod';
import TextareaFieldInput from 'components/Global/textareaField';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	formatToDateOnly,
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
import { AddSalaryModalProps } from './types';
import { useSelector } from 'react-redux';

const AddSalaryModal: FC<AddSalaryModalProps> = (props) => {
	const {
		handleCancel,
		isAddSalaryModalOpen,
		employeeId,
		fetchSalary,
		setIsEmployeeSalaryExists,
	} = props;

	const [salaryDetails, setSalaryDetails] = useState<any>({
		monthlySalary: 0,
		hourlySalary: 0,
		midMonthSalary: 0,
		effectiveStartDate: '',
		notes: '',
		year: dayjs(),
	});

	const [selectedYear, setSelectedYear] = useState<any>(dayjs());
	const [selectedPayPeriod, setSelectedPayPeriod] = useState('');

	const [formError, setFormError] = useState<any>({
		monthlySalary: false,
		hourlySalary: false,
		midMonthSalary: false,
		effectiveStartDate: false,
		notes: false,
	});

	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	// const [payPeriodData, setPayPeriodData] = useState<any>([]);
	// const [isPayPeriodLoading, setIsPayPeriodLoading] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [payPeriodError, setPayPeriodError] = useState(false);
	const { data: payPeriodData } = useSelector(
		(state: any) => state.payPeriod
	);

	const myButtons = [
		{
			text: 'Save',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				handleSubmit();
			},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				closeModal();
			},
		},
	];

	// const getPayPeriodData = async (year: number) => {
	// 	setIsPayPeriodLoading(true);
	// 	try {
	// 		const response = await payPeriodApi.getPayPeriodOptions(year);
	// 		setPayPeriodData(response.data.data);
	// 	} catch (err: any) {
	// 		let message =
	// 			err.response?.data?.message ||
	// 			'Something went wrong in getting pay period data';
	// 		toastText(message, 'error');
	// 	}
	// 	setIsPayPeriodLoading(false);
	// };

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{ ...salaryDetails },
			{ ...formError }
		);

		setFormError(checkFormError);

		if (invalidText(selectedPayPeriod)) {
			setPayPeriodError(true);
			return;
		}

		if (hasFormError(checkFormError)) {
			return;
		} else {
			const data = {
				...salaryDetails,
				id: searchParams.get('employeeId'),
				midMonthSalary: parseFloat(salaryDetails.midMonthSalary),
				hourlySalary: parseFloat(salaryDetails.hourlySalary),
				monthlySalary: parseFloat(salaryDetails.monthlySalary),
				effectiveStartDate: formatToDateOnly(
					salaryDetails.effectiveStartDate
				),
				payperiodId: selectedPayPeriod,
				// payperiodId: salaryDetails.payperiodId,
			};

			setIsLoading(true);
			if (searchParams.get('employeeId')) {
				try {
					const response = await salaryApi.createSalary(data);
					let message =
						response?.data?.message ||
						'Employee salary created successfully';
					toastText(message, 'success');
					setIsEmployeeSalaryExists(true);

					closeModal();
				} catch (err: any) {
					let message =
						err.response?.data?.message ||
						'Something went wrong in creating employee salary';
					toastText(message, 'error');
				}
				fetchSalary();
			} else {
				toastText(
					'Create an employee before adding salary details.',
					'error'
				);
			}
			setIsLoading(false);
		}
	};

	const closeModal = () => {
		setSalaryDetails({
			monthlySalary: null,
			hourlySalary: null,
			midMonthSalary: null,
			effectiveStartDate: '',
			// payperiodId: '',
			notes: '',
		});
		setFormError({
			monthlySalary: false,
			hourlySalary: false,
			midMonthSalary: false,
			effectiveStartDate: false,
			// payperiodId: false,
			notes: false,
		});
		setSelectedPayPeriod('');
		setSelectedYear(dayjs());

		handleCancel();
	};

	const handleChange = (
		value: string | number | null | String[],
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}

		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}
		if (name === 'year') {
			setSalaryDetails((prev: any) => ({
				...prev,
				year: value,
			}));
			// getPayPeriodData(dayjs(value as any).year());
			// setYear(value);
		}
		if (name === 'monthlySalary') {
			const newMonthlySalary = Number(value);
			const newHourlySalary = newMonthlySalary
				? (newMonthlySalary / 208).toFixed(2)
				: 0;

			setSalaryDetails((prev: any) => ({
				...prev,
				hourlySalary: newHourlySalary,
			}));
		}
		if (name === 'hourlySalary') {
			const newHourlySalary = Number(value);
			const newMonthlySalary = newHourlySalary
				? (newHourlySalary * 208).toFixed(2)
				: 0;

			setSalaryDetails((prev: any) => ({
				...prev,
				monthlySalary: newMonthlySalary,
			}));
		}
		// if (name === 'payperiodId') {
		// 	const findPayPeriod = payPeriodData.find(
		// 		(item: any) => item.value === value
		// 	);
		// 	setSalaryDetails((prev: any) => ({
		// 		...prev,
		// 		payperiodId: value,
		// 		effectiveStartDate: dayjs(findPayPeriod?.startDate),
		// 	}));
		// }
		OnChange(value, name);
	};

	const OnChange = (
		value: string | number | null | String[],
		key: string
	) => {
		setSalaryDetails((prev: any) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	// useEffect(() => {
	// 	getPayPeriodData(dayjs().year());
	// }, []);

	const handlePayPeriod = (value: string) => {
		if (invalidText(value)) {
			setPayPeriodError(true);
			return;
		}

		setSelectedPayPeriod(value);
		const _findPayPeriod = payPeriodData.find(
			(item: any) => item.value === value
		);

		setSalaryDetails((prev: any) => ({
			...prev,
			effectiveStartDate: _findPayPeriod.startDate
				? dayjs(_findPayPeriod?.startDate)
				: '',
		}));
	};

	const handleYearChange = (value: any) => {
		setSelectedYear(value);
		setSalaryDetails((prev: any) => ({
			...prev,
			year: value,
			effectiveStartDate: '',
		}));
		setSelectedPayPeriod('');
	};

	return (
		<Modal
			open={isAddSalaryModalOpen}
			// onOk={handleSubmit}
			onCancel={closeModal}
			okText={'Save'}
			closable={false}
			width={800}
			footer={null}
			title={<h2>Add salary</h2>}
			className="salary__popup"
		>
			<Row className={styles['form-container-card']} gutter={50}>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<h3>EmployeeId : {employeeId}</h3>
				</Col>
			</Row>
			<Row className={styles['form-container-card']} gutter={50}>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputNumberField
						name="monthlySalary"
						value={salaryDetails.monthlySalary}
						label="Monthly Salary"
						required={true}
						helperText="Monthly salary required"
						onChange={(value) => {
							handleChange(value, 'monthlySalary', true);
						}}
						suffix={'K'}
						isError={formError.monthlySalary}
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputNumberField
						name="hourlySalary"
						value={salaryDetails.hourlySalary}
						label="Hourly salary"
						required={true}
						helperText="Hourly salary required"
						onChange={(value) => {
							handleChange(value, 'hourlySalary', true);
						}}
						suffix={'K'}
						isError={formError.hourlySalary}
					/>
				</Col>
				<GlobalPayPeriod
					selectedYear={selectedYear}
					handleSelectedYear={(value) => {
						handleYearChange(value);
					}}
					selectedPayPeriod={selectedPayPeriod}
					handlePayPeriod={(value) => handlePayPeriod(value)}
					rowStyle={{
						marginTop: '10px',
					}}
					payPeriodError={payPeriodError}
					isRow={false}
				/>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<DatePickerField
						name="effectiveStartDate"
						value={salaryDetails.effectiveStartDate}
						label="Effective Start Date"
						required={true}
						helperText="Effective start date required"
						onChange={(value) => {
							// handleChange(value, 'effectiveStartDate', true);
						}}
						isError={formError.effectiveStartDate}
						disabled={true}
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputNumberField
						name="midMonthSalary"
						value={salaryDetails.midMonthSalary}
						label="Mid month salary"
						required={true}
						helperText="Mid month salary required"
						onChange={(value) => {
							handleChange(value, 'midMonthSalary', true);
						}}
						suffix={'K'}
						isError={formError.midMonthSalary}
					/>
				</Col>
				<Col span={24} className={`${styles['col']} margin-top-10`}>
					<TextareaFieldInput
						name="notes"
						value={salaryDetails.notes}
						label="Notes"
						required={true}
						helperText="Notes required"
						onChange={(value) => {
							handleChange(value, 'notes', true);
						}}
						isError={formError.notes}
					/>
				</Col>
			</Row>
			<div className={styles['buttons']}>
				<Buttons buttons={myButtons} />
			</div>
		</Modal>
	);
};

export default AddSalaryModal;

import React from 'react'
import styles from './index.module.scss'


const Header = () => {
  return (
		<div className={styles['inline-container']}>
			<p>Year:</p>
			<p>Month:</p>
			<p>Pay group:</p>
			<p>Currency:</p>
			<p>Department:</p>
			<p>Branch:</p>
		</div>
  );
}

export default Header;
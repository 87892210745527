import { Table } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import React, { useContext, useState } from 'react';
import styles from './index.module.scss';

const { Column } = Table;

type Props = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	isLoading: boolean;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};

const PaySlipTable: React.FC<Props> = (props: Props) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		isLoading,

		tableChangeHandler,
	} = props;

	const permissions = useContext(PermissionContext);
	const [selectionType, setSelectionType] = useState();

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
			console.log(
				`selectedRowKeys: ${selectedRowKeys}`,
				'selectedRows: ',
				selectedRows
			);
		},
		getCheckboxProps: (record: any) => ({
			disabled: record.name === 'Disabled User',
		}),
	};
	const dummyData = [
		{
			id: '1',
			firstName: 'John',
			lastName: 'Doe',
			optionalEmployeeId: 'E001',
			paymentSlips: 'January, February, March',
		},
		{
			id: '2',
			firstName: 'Jane',
			lastName: 'Smith',
			optionalEmployeeId: 'E002',
			paymentSlips: 'April, May, June',
		},
		{
			id: '3',
			firstName: 'Alice',
			lastName: 'Johnson',
			optionalEmployeeId: 'E003',
			paymentSlips: 'July, August, September',
		},
		{
			id: '4',
			firstName: 'Bob',
			lastName: 'Brown',
			optionalEmployeeId: 'E004',
			paymentSlips: 'October, November, December',
		},
		{
			id: '2',
			firstName: 'Jane',
			lastName: 'Smith',
			optionalEmployeeId: 'E002',
			paymentSlips: 'April, May, June',
		},
		{
			id: '3',
			firstName: 'Alice',
			lastName: 'Johnson',
			optionalEmployeeId: 'E003',
			paymentSlips: 'July, August, September',
		},
		{
			id: '4',
			firstName: 'Bob',
			lastName: 'Brown',
			optionalEmployeeId: 'E004',
			paymentSlips: 'October, November, December',
		},
	];
	return (
		<>
			<div className={styles['paySlip-dynamic-table']}>
				<Table
					dataSource={dummyData}
					scroll={{ y: 'calc(90vh - 360px)' }}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,
						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
					rowSelection={{
						type: selectionType,
						...rowSelection,
					}}
				>
					{/* <Column
						width={'10%'}
						key="checkbox"
						render={(text, record: any) => (
							<Checkbox
								onChange={({ target: { value } }) => {
									setSelectionType(value);
								}}
								value={selectionType}
							/>
						)}
					/> */}
					<Column
						title="Employee Name and Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						width={'40%'}
						sorter={true}
						render={(text, record: any) => (
							<>
								<span>
									{record.firstName} {record.lastName}
								</span>
								<br />
								<span className="color-purple">
									{record?.optionalEmployeeId}
								</span>
							</>
						)}
					/>

					<Column
						title="Pay Slips"
						width={'40%'}
						dataIndex="paymentSlips"
						key="paymentSlips"
					/>
				</Table>
			</div>
		</>
	);
};

export default PaySlipTable;

import { Col, Row } from 'antd';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmployeeLeaveImage } from 'utils/svgs';
import { toastText } from 'utils/utils';
import EmployeeLeavesTable from './Table';
import styles from './index.module.scss';
import GlobalHeader from '../GlobalHeader';

const EmployeeLeavesComponent = (props: any) => {
	const navigate = useNavigate();
	const [employeeLeaveData, setEmployeeLeaveData] = useState<any[]>([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};
	const fetchEmployeeLeave = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};
			setIsLoading(true);
			// const leaveRequest = await leaveRequestApi.getLeaveRequests(query);
			// setLeaveRequestData(leaveRequest.data.data);
			// setTotalRecords(leaveRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching leave request.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchEmployeeLeave();
	}, [currentPage, sortField, sortOrder, pageSize]);

	return (
		<>
			<Row>
				<Col span={14}>
					<div className={styles['employee-leaves__table']}>
						<GlobalHeader/>
						<EmployeeLeavesTable
							tableData={employeeLeaveData}
							totalRecords={totalRecords}
							currentPage={currentPage}
							pageSize={pageSize}
							isLoading={isLoading}
							tableChangeHandler={tableChangeHandler}
						/>
					</div>
				</Col>
				<Col span={2}></Col>
				<Col span={8}>
					<div>
						<EmployeeLeaveImage />
					</div>
				</Col>
			</Row>
		</>
	);
};

export default EmployeeLeavesComponent;

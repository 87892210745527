import { Button, Modal, Typography } from 'antd';
import { useState } from 'react';
import styles from './index.module.scss';
import { CloseOutlined, EyeOutlined,} from '@ant-design/icons';
import AddPreviewModal from '../AddPreviewModel';
import Title from 'antd/es/typography/Title';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
};

const bankOptions = [
	{
		id: 1,
		logo: (
			<img
				src={`/assets/images/first-capital-bank.jpg`}
				alt="First Capital Bank"
				className={styles['bank-logo']}
			/>
		),
	},
	{
		id: 2,
		logo: (
			<img
				src={`/assets/images/Stanbic-Bank-Logo.jpg`}
				alt="Stanbic bank"
				className={styles['bank-logo']}
			/>
		),
	},
	{
		id: 3,
		logo: (
			<img
				src={`/assets/images/bank.png`}
				alt="Other bank"
				className={styles['bank-logo']}
			/>
		),
	},
];

const AddModal = (props: Props) => {
	const { isOpen, handleCancel } = props;
	const [selectedBank, setSelectedBank] = useState<number>(1);
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);

	const handleBankSelect = (bankId: number) => {
		setSelectedBank(bankId);
	};

	const handlePreview = () => {
		console.log(`Selected bank ID: ${selectedBank}`);
		setIsPreviewOpen(true);
	};

	const handlePreviewCancel = () => {
		setIsPreviewOpen(false);
	};

	const handleProceedToPayment = () => {
		// Logic for proceeding to payment
		console.log(
			'Proceeding to payment with selected bank ID:',
			selectedBank
		);
		setIsPreviewOpen(false);
	};

	return (
		<>
			<Modal
				open={isOpen}
				closable={false}
				onCancel={handleCancel}
				width={500}
				footer={
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						<Button key="cancel" onClick={handleCancel}>
							Cancel
						</Button>

						<Button
							key="preview"
							onClick={handlePreview}
							type="primary"
							style={{ marginLeft: '10px' }}
						>
							Preview
						</Button>
					</div>
				}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>Select Bank</Title>
						<Button
							icon={<CloseOutlined />}
							onClick={handleCancel}
						/>
					</div>

					<div className={styles['modal-body']}>
						{bankOptions.map((bank) => (
							<div
								key={bank.id}
								className={`${styles['bank-option']} ${
									selectedBank === bank.id
										? styles['selected']
										: ''
								}`}
								onClick={() => handleBankSelect(bank.id)}
							>
								<div className={styles['bank-details']}>
									{bank.logo}
								</div>
								<Button
									type="default"
									className={styles['check-balance-button']}
								>
									<EyeOutlined
										style={{ marginRight: '2px' }}
									/>
									Check balance
								</Button>
							</div>
						))}
					</div>
				</div>
			</Modal>

			<AddPreviewModal
				isOpen={isPreviewOpen}
				handleCancel={handlePreviewCancel}
				handleProceedToPayment={handleProceedToPayment}
			/>
		</>
	);
};

export default AddModal;

import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Steps } from 'antd';
import { payrollApi } from 'Api/payroll';
import Buttons from 'components/Global/Buttons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseSvg } from 'utils/svgs';
import { toastText } from 'utils/utils';
import EmployeeLeaveComponent from './EmployeeLeaves';
import styles from './index.module.scss';
import './index.scss';
import NetPayComponent from './NetPay';
import PayGroupSetupComponent from './PayGroup';
import PaySlipComponent from './PaySlips';
import TotalDeductionComponent from './TotalDeduction';
import TotalEarningComponent from './TotalEarning';
import WorkHourComponent from './WorkHours';

const { Step } = Steps;

const RunPayrollComponent: React.FC = () => {
	const navigate = useNavigate();

	const [current, setCurrent] = useState(0);

	// Pay Period

	const [selectedYear, setSelectedYear] = useState('');
	const [selectedPayPeriod, setSelectedPayPeriod] = useState('');

	// Pay Group

	const [selectedPayGroup, setSelectedPayGroup] = useState([]);
	const [payGroupOptions, setPayGroupOptions] = useState([]);
	const [selectedEmployees, setSelectedEmployees] = useState<any>([]);

	const [remainingEmployees, setRemainingEmployees] = useState([]);

	const fetchRemainingPayGroups = async (selectedPayPeriod: string) => {
		try {
			const response = await payrollApi.getRemainingPayGroup({
				payPeriodId: selectedPayPeriod,
			});

			const data = response.data.data.map((item: any) => {
				return {
					...item,
					label: item.name,
					value: item.id,
				};
			});

			setPayGroupOptions(data);
		} catch (err) {
			toastText('Something went wrong in fetching pay-groups.', 'error');
		}
	};

	const fetchRemainingEmployees = async (selectedPayPeriod: string) => {
		try {
			const response = await payrollApi.getRemainingEmployeeList({
				payPeriodId: selectedPayPeriod,
			});

			const options = response.data?.data
				? response.data?.data?.map((item: any) => {
						return {
							value: item.id,
							label: `${item.firstName} ${item.lastName}`,
							employeeId: item.employeeId,
							isAdded: false,
						};
				  })
				: [];

			setRemainingEmployees(options);
		} catch (err) {
			toastText(
				'Something went wrong in fetching remaining employees.',
				'error'
			);
		}
	};

	const addEmployee = async (employeeId: string) => {
		setSelectedEmployees((prev: any) => {
			return [...prev, employeeId];
		});
		setRemainingEmployees((emp: any) => {
			if (emp.value === employeeId) {
				return {
					...emp,
					isAdded: true,
				};
			} else {
				return {
					...emp,
					isAdded: false,
				};
			}
		});
	};

	const removeEmployee = async (employeeId: string) => {
		setSelectedEmployees((prev: any) => {
			return prev.filter((item: any) => item !== employeeId);
		});

		setRemainingEmployees((emp: any) => {
			if (emp.value === employeeId) {
				return {
					...emp,
					isAdded: false,
				};
			} else {
				return {
					...emp,
					isAdded: true,
				};
			}
		});
	};

	useEffect(() => {
		if (selectedPayPeriod) {
			fetchRemainingPayGroups(selectedPayPeriod);
			fetchRemainingEmployees(selectedPayPeriod);
		}
	}, [selectedPayPeriod]);

	useEffect(() => {
		if (selectedPayGroup && payGroupOptions.length > 0) {
			let employees: any = [];

			for (const i of selectedPayGroup) {
				const payGroup: any = payGroupOptions.find(
					(payGroup: any) => payGroup.id === i
				);
				if (payGroup) {
					employees = [...employees, ...payGroup.PayGroupEmployee];
				}
			}
			console.log('Employees: ', employees);
		}
	}, [selectedPayGroup, payGroupOptions]);

	const handleSubmit = () => {
		console.log('handle Submit');
	};

	const steps = [
		{
			title: '1. Select pay group',
			content: (
				<PayGroupSetupComponent
					selectedPayGroup={selectedPayGroup}
					handlePayGroup={(value) => setSelectedPayGroup(value)}
					selectedYear={selectedYear}
					handleSelectedYear={(value) => setSelectedYear(value)}
					selectedPayPeriod={selectedPayPeriod}
					handlePayPeriod={(value) => setSelectedPayPeriod(value)}
					payGroupOptions={payGroupOptions}
					remainingEmployees={remainingEmployees}
					addEmployee={addEmployee}
					removeEmployee={removeEmployee}
				/>
			),
		},
		{
			title: '2. Working hours',
			content: <WorkHourComponent />,
		},
		{
			title: "3. Employee's leave",
			content: <EmployeeLeaveComponent />,
		},
		{
			title: '4. Total deductions',
			content: <TotalDeductionComponent />,
		},
		{
			title: '5. Total earnings',
			content: <TotalEarningComponent />,
		},
		{ title: '6. Net pay', content: <NetPayComponent /> },
		{
			title: '7. Pay Slips',
			content: <PaySlipComponent />,
		},
	];

	const buttons = [
		{
			text: 'Back',
			isLoading: false,
			className: 'secondary-button',
			minWidth: '12rem',
			disabled: current === 0,
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => prev(),
		},
		{
			text: 'Save as Draft',
			className: 'btn-blue',
			// fontSize: '1.8rem',
			minWidth: '12rem',
			isLoading: false,
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {},
		},
		{
			text:
				current === steps.length - 1
					? 'Send for Approval'
					: 'Save & Next',
			className: 'btn-blue',
			minWidth: '12rem',
			isLoading: false,
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				if (current === steps.length - 1) {
					handleSubmit();
				} else {
					next();
				}
			},
		},
	];

	const next = () => setCurrent(current + 1);
	const prev = () => setCurrent(current - 1);

	return (
		<>
			<div className="modal-animation">
				<div className={styles['run-payroll']}>
					<div className={styles['run-payroll__header']}>
						<div className={styles['run-payroll__header__title']}>
							Run Payroll
						</div>
						<div
							className={styles['run-payroll__header__actions']}
							onClick={() => {
								navigate('/payroll-overview');
							}}
						>
							<CloseSvg />
						</div>
					</div>
					<div className={styles['run-payroll__table']}>
						<div className="step-container">
							<div className="steps-header">
								<Steps
									current={current}
									onChange={setCurrent}
									className="custom-steps"
									size="small"
									labelPlacement="vertical"
								>
									{steps.map((item, index) => (
										<Step
											key={index}
											title={
												<>
													{item.title}
													{current > index && (
														<div className="completed-text">
															Completed
														</div>
													)}
												</>
											}
											disabled={current > index}
										/>
									))}
								</Steps>
							</div>
						</div>

						{current === steps.length - 1 && (
							<div className="download-button-container">
								<Button
									type="primary"
									className="download-slip-button"
								>
									<DownloadOutlined /> Download Pay Slip
								</Button>
							</div>
						)}

						<Divider className="steps-divider" />

						<div className="steps-content">
							{steps[current].content}
						</div>
						<div className="buttons-container">
							<Buttons
								buttons={buttons.filter(
									(button) =>
										!(
											button.text === 'Back' &&
											current === 0
										)
								)}
							></Buttons>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RunPayrollComponent;

import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import './index.scss';
import StatutoryComponent from 'components/Settings/PayrollSettings/StatutoryComponent';

export const StatutoryComponentPage = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Statutory_Components');

	return <div>{check && <StatutoryComponent />}</div>;
};

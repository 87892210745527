import { Button, Modal, Table } from 'antd';
import React, { useState } from 'react';
import styles from './index.module.scss';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import AddModal from '../AddModal';
import { EyeOutlined } from '@ant-design/icons';
import { DeleteActionSvg, EditActionSvg } from 'utils/svgs';

type TableProps = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
};

const PayrollTable = (props: TableProps) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
	} = props;

	const [isModalVisible, setIsModalVisible] = useState(false);

	// Function to open the modal
	const showModal = () => {
		setIsModalVisible(true);
	};

	// Function to handle modal OK or close actions
	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const staticTableData = [
		{
			id: 1,
			currency: 'USD',
			payGroup: 'Monthly Salaries',
			payMonth: 'August',
			payDate: '2023-08-25',
			createdBy: 'John Doe',
			//	status: 'Pending',
			action: 'Approve',
			initiatePayment: true,
		},
		{
			id: 2,
			currency: 'EUR',
			payGroup: 'Bonus Payments',
			payMonth: 'July',
			payDate: '2023-07-15',
			createdBy: 'Jane Smith',
			//	status: 'Completed',
			action: 'View',
			initiatePayment: true,
		},
	];

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={staticTableData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Currency"
					dataIndex="currency"
					key="currency"
					sorter={false}
									className="bg-white"
				/>
				<Column
					title="Pay group"
					dataIndex="payGroup"
					key="payGroup"
					sorter={false}
									className="bg-white"
				/>
				<Column
					title="Pay month"
					dataIndex="payMonth"
					key="payMonth"
					sorter={false}
									className="bg-white"
				/>
				<Column
					title="Pay Date"
					dataIndex="payDate"
					key="payDate"
					render={(value: string) =>
						dayjs(value).format('DD/MM/YYYY')
					}
					sorter={false}
									className="bg-white"
				/>
				<Column
					title="Created By"
					dataIndex="createdBy"
					key="createdBy"
					sorter={false}
									className="bg-white"
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					sorter={false}
									className="bg-white"
				/>
				<Column
					title="Action"
					dataIndex="action"
					key="action"
					sorter={false}
									className="bg-white"
					render={(_, record) => (
						<div>
							<Button
								type="text"
								icon={<EditActionSvg />}
								onClick={() => console.log('Edit', record)}
							/>
							<Button
								type="text"
								icon={
									<EyeOutlined
										style={{
											color: '#000',
											fontSize: '22px',
										}}
									/>
								}
								onClick={() => console.log('View', record)}
							/>
							<Button
								type="text"
								icon={<DeleteActionSvg />}
								onClick={() => console.log('Delete', record)}
							/>
						</div>
					)}
				/>
				<Column
					title="Payment"
					dataIndex="initiatePayment"
					key="initiatePayment"
					sorter={false}
								className="bg-white"
					render={(_, record) => (
						<Button type="primary" onClick={showModal}>
							Initiate Payment
						</Button>
					)}
				/>
			</Table>
			<AddModal isOpen={isModalVisible} handleCancel={handleCancel} />
		</div>
	);
};

export default PayrollTable;

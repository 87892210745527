
import './index.scss';
import PayrollComponent from 'components/Payroll/PayrollComponent';

export const Payroll = () => {
	return (
		<div>
			<PayrollComponent />
		</div>
	);
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PayrollHeader from './Header';
import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { payrollApi } from 'Api/payroll';
import { toastText } from 'utils/utils';
import PayrollTable from './Table';

const PayrollOverview = () => {
	const navigate = useNavigate();

	const [searchValue, setSearchValue] = useState('');
	const [date, setDate] = useState<any>(dayjs());
	const [statusFilterValue, setStatusFilterValue] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [sortOrder, setSortOrder] = useState('');
	const [totalRecords, setTotalRecords] = useState(0);
	const [payrollData, setPayrollData] = useState<any[]>([]);
	const handleSearch = (value: string) => {
		setSearchValue(value);
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const handleStatusFilter = (value: string) => {
		setStatusFilterValue(value);
	};

	const handleSearchApi = async (value: string) => {
		console.log('🚀 ~ handleSearchApi ~ value:', value);
	};

	const handleDatePicker = (value: any) => {
		setDate(value);
	};
	const buttons = [
		{
			text: 'Create Payroll',
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				navigate('/run-payroll');
			},
			disabled: false,
		},
	];

	const fetchAllPayolls = async () => {
		try {
			const query = {
				page: currentPage,
				searchText: searchValue,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
				month: dayjs(date).format('MM'),
			};
			setIsLoading(true);
			// const payrollData = await payrollApi.getAllPayrolls(query);

			// setPayrollData(payrollData.data.data );
			// setTotalRecords(payrollData.data.total);
			setPayrollData([]);
			setTotalRecords(0);
		} catch (err: any) {
			let message = 'Something went wrong in fetching payrolls request.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		fetchAllPayolls();
	}, [
		currentPage,
		pageSize,
		searchValue,
		date,
		statusFilterValue,
		sortOrder,
		sortField,
	]);
	return (
		<>
			<PayrollHeader
				searchText={searchValue}
				handleSearch={handleSearch}
				handleSearchApi={handleSearchApi}
				handleDatePicker={handleDatePicker}
				dateValue={date}
				StatusFilter={statusFilterValue}
				handleStatusFilter={handleStatusFilter}
				button={buttons}
			/>
			<PayrollTable
				tableData={payrollData}
				totalRecords={totalRecords}
				currentPage={currentPage}
				pageSize={pageSize}
				isLoading={isLoading}
				tableChangeHandler={tableChangeHandler}
			/>
		</>
	);
};

export default PayrollOverview;

import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastText } from 'utils/utils';
import WorkingHoursTable from './Table';
import styles from './index.module.scss';
import { workHoursApi } from 'Api/workHours';
import GlobalHeader from '../GlobalHeader';

const WorkHourComponent = (props: any) => {
	const navigate = useNavigate();
	const [workHoursData, setWorkHoursData] = useState<any[]>([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};
	const fetchWorkHours = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};
			setIsLoading(true);
			const workHours = await workHoursApi.getWorkHours(query);
			setWorkHoursData(workHours.data.data);
			setTotalRecords(workHours.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching work hours.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchWorkHours();
	}, [currentPage, sortField, sortOrder, pageSize]);

	return (
		<div className={styles['work-hours__table']}>
			<GlobalHeader />
			<WorkingHoursTable
				tableData={workHoursData}
				totalRecords={totalRecords}
				currentPage={currentPage}
				pageSize={pageSize}
				isLoading={isLoading}
				tableChangeHandler={tableChangeHandler}
			/>
		</div>
	);
};

export default WorkHourComponent;

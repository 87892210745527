import { useSearchParams } from 'react-router-dom';

import styles from './index.module.scss';
import { useState } from 'react';
import { employeeApi } from 'Api/employee';
import dayjs from 'dayjs';
import { toastText } from 'utils/utils';
import Buttons from 'components/Global/Buttons';
import DeductionsModal from './DeductionBody';
import YearMonthPicker from './DeductionBody/DatePicker';
import GlobalPayPeriod from 'components/Global/PayPeriod';

const DeductionTab = (props: any) => {
	const { year, month, employeeId, onYearChange, onMonthChange, activeTab } =
		props;
	const [searchParams, setSearchParams] = useSearchParams();
	const [earningsAmount, setEarningsAmount] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedPayPeriod, setSelectedPayPeriod] = useState('');
	const myButtons = [
		{
			text: 'Save',
			isLoading: isLoading,
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '12rem',
			disabled: isLoading,
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				handleSave();
			},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			disabled: isLoading,
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				// handleCancel();
			},
		},
	];

	const [data, setData] = useState<any>();
	const [isFetchDataLoading, setIsFetchDataLoading] =
		useState<boolean>(false);

	const handleDataChange = (data: any) => {
		setData(data);
		const total = data?.reduce(
			(sum: any, item: any) =>
				item.isChecked ? sum + parseFloat(item?.amount || '0.00') : sum,
			0
		);
		setEarningsAmount(total);
	};

	const handleFetchLoading = (status: boolean) => {
		setIsFetchDataLoading(status);
	};

	const handleSave = async () => {
		// const formattedData = data.map((item: any) => {
		// 	const { formula, ...rest } = item;
		// 	return rest;
		// });
		const finalData = {
			payPeriodId: selectedPayPeriod,
			employeeId,
			data: data,
		};
		try {
			setIsLoading(true);
			const apiCall = await employeeApi.createEmployeeDeductions(
				finalData
			);

			let message =
				apiCall?.data?.message || `Deductions saved successfully`;
			toastText(message, 'success');
		} catch (error: any) {
			let message =
				error?.data?.message ||
				`something went wrong failed to save deductions`;
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div>
			<div className={styles.headerBox}>
				<div className={styles.headerContent}>
					<div className={styles.titleContainer}>
						<div className={styles.titleText}>{'Deductions'}</div>
						<div className={styles.separation}></div>
						<div className={styles.earningsCount}>
							<div className={styles.titleEarnings}>
								Total Deductions
							</div>
							<div className={styles.earningsAmount}>
								K
								{!isFetchDataLoading &&
									earningsAmount?.toFixed(2)}
							</div>
						</div>
					</div>
					<div className={styles.yearMonthContent}>
						<GlobalPayPeriod
							selectedYear={year}
							handleSelectedYear={onYearChange}
							selectedPayPeriod={selectedPayPeriod}
							handlePayPeriod={(value) =>
								setSelectedPayPeriod(value)
							}
							isRow={true}
						/>
					</div>
				</div>
			</div>
			<DeductionsModal
				onDataChange={(data) => handleDataChange(data)}
				onLoadingChange={(loading) => handleFetchLoading(loading)}
				month={month}
				year={year}
				activeTab={activeTab}
				employeeId={searchParams.get('employeeId') as string}
				payPeriodId={selectedPayPeriod}
			/>
			<div className={styles.buttons}>
				{!isFetchDataLoading && <Buttons buttons={myButtons} />}
			</div>
		</div>
	);
};

export default DeductionTab;

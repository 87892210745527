import { Radio, Table, Tooltip } from 'antd';
import styles from './index.module.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Column } = Table;

type Props = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	isLoading: boolean;

	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};
const EmployeeLeavesTable = (props: Props) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		isLoading,
		tableChangeHandler,
	} = props;
	const dummyData = [
		{
			id: '1',
			firstName: 'John',
			lastName: 'Doe',
			optionalEmployeeId: 'E001',
			leaves: 5,
			deductionType: 'deductedFromLeaveBalance',
		},
		{
			id: '2',
			firstName: 'Jane',
			lastName: 'Smith',
			optionalEmployeeId: 'E002',
			leaves: 3,
			deductionType: 'deductedFromSalary',
		},
		{
			id: '3',
			firstName: 'Alice',
			lastName: 'Johnson',
			optionalEmployeeId: 'E003',
			leaves: 7,
			deductionType: 'deductedFromLeaveBalance',
		},
		{
			id: '4',
			firstName: 'Bob',
			lastName: 'Brown',
			optionalEmployeeId: 'E004',
			leaves: 2,
			deductionType: 'deductedFromSalary',
		},
	];

	return (
		<>
			<div className={styles['employeeLeaves-dynamic-table']}>
				<Table
					dataSource={dummyData}
					scroll={{ y: 'calc(90vh - 360px)' }}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,
						// onShowSizeChange: pageSizeHandler,
						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Employee Name and Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						width={'30%'}
						sorter={true}
						render={(text, record: any) => (
							<>
								<span>
									{record.firstName} {record.lastName}
								</span>
								<br />
								<span className="color-purple">
									{record?.optionalEmployeeId}
								</span>
							</>
						)}
					/>

					<Column
						title="No. of Leaves"
						dataIndex="leaves"
						key="leaves"
						width={'20%'}
						sorter={true}
						// render={(value: string) =>
						// 	dayjs(value).format('DD/MM/YYYY')
						// }
					/>
					<Column
						title={
							<>
								Deducted from Leave Balance{' '}
								<Tooltip title="Select this option if the leave taken by the employee should be deducted from their available leave balance.">
									<ExclamationCircleOutlined />
								</Tooltip>
							</>
						}
						key="deductedFromLeaveBalance"
						width={'30%'}
						render={(text, record: any) => (
							<Radio
								checked={
									record.deductionType ===
									'deductedFromLeaveBalance'
								}
							/>
						)}
					/>
					<Column
						title={
							<>
								Deducted from Salary{' '}
								<Tooltip title="Select this option if the leave taken by the employee should result in a deduction from their salary.">
									<ExclamationCircleOutlined />
								</Tooltip>
							</>
						}
						key="deductedFromSalary"
						width={'30%'}
						render={(text, record: any) => (
							<Radio
								checked={
									record.deductionType ===
									'deductedFromSalary'
								}
							/>
						)}
					/>
				</Table>
			</div>
		</>
	);
};
export default EmployeeLeavesTable;

import { Radio, Table } from 'antd';
import styles from './index.module.scss';

const { Column } = Table;

type Props = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	isLoading: boolean;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};
const NetPayTable = (props: Props) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		isLoading,
		tableChangeHandler,
	} = props;
	const dummyData = [
		{
			id: '1',
			firstName: 'John',
			lastName: 'Doe',
			optionalEmployeeId: 'EMP001',
			leaves: 5,
			deductionType: 'deductedFromLeaveBalance',
		},
		{
			id: '2',
			firstName: 'Jane',
			lastName: 'Smith',
			optionalEmployeeId: 'EMP002',
			leaves: 3,
			deductionType: 'deductedFromSalary',
		},
		{
			id: '3',
			firstName: 'Alice',
			lastName: 'Johnson',
			optionalEmployeeId: 'EMP003',
			leaves: 4,
			deductionType: 'deductedFromLeaveBalance',
		},
		{
			id: '4',
			firstName: 'Bob',
			lastName: 'Brown',
			optionalEmployeeId: 'EMP004',
			leaves: 2,
			deductionType: 'deductedFromSalary',
		},
		{
			id: '5',
			firstName: 'Emily',
			lastName: 'White',
			optionalEmployeeId: 'EMP005',
			leaves: 6,
			deductionType: 'deductedFromLeaveBalance',
		},
	];

	return (
		<>
			<div className={styles['netPay-dynamic-table']}>
				<Table
					dataSource={dummyData}
					scroll={{ y: 'calc(90vh - 360px)' }}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,
						// onShowSizeChange: pageSizeHandler,
						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Employee Name and Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						sorter={true}
						width={'40%'}
						render={(text, record: any) => (
							<>
								<span>
									{record.firstName} {record.lastName}
								</span>
								<br />
								<span className="color-purple">
									{record?.optionalEmployeeId}
								</span>
							</>
						)}
					/>

					<Column
						title="Total Earnings"
						dataIndex="earnings"
						key="earnings"
						width={'20%'}
						sorter={true}
					/>
					<Column
						title="Total Deductions"
						key="totalDeductions"
						width={'20%'}
						sorter={true}
					/>
					<Column
						title="Total Pay"
						dataIndex="netPay"
						key="netPay"
						width={'20%'}
						sorter={true}
					/>
				</Table>
			</div>
		</>
	);
};
export default NetPayTable;

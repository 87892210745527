import { WarningOutlined } from '@ant-design/icons';
import { Col, Form, Input, Radio, Row, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import Buttons from 'components/Global/Buttons';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { fetchConstantDropdownAction } from './../../../../redux/actions/constantDropdownAction';

const DirectDeposit = () => {
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);

	const renderLabel = (label: string, required: boolean) => (
		<span className={styles['heading']}>
			{label} {required && <span className={styles['required']}>*</span>}
		</span>
	);
	const dispatch = useDispatch<AppDispatch>();
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const myButtons = [
		{
			text: 'Save',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				console.log('Clicked cancel');
			},
		},
	];
	// useEffect(() => {
	// 	dispatch(fetchConstantDropdownAction({}));
	// }, []);

	return (
		<div className={styles.form}>
			<div className={styles['form-container']}>
				<Form
					form={form}
					name="dependencies"
					autoComplete="off"
					layout="vertical"
					requiredMark={false}
					onFinish={(values) => {
						console.log('Form Submitted:', values);
					}}
				>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								label={renderLabel('Account Holder Name', true)}
								name="accountHolderName"
								labelCol={{
									className: styles['form-container-label'],
								}}
								rules={[
									{
										required: true,
										message:
											'Account Holder Name is required',
									},
									{
										pattern: /^[A-Za-z\s]+$/,
										message:
											'Account Holder Name must be valid',
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label={renderLabel('Bank Name', true)}
								name="bankName"
								labelCol={{
									className: styles['form-container-label'],
								}}
								rules={[
									{
										required: true,
										message: 'Bank Name is required',
									},
								]}
							>
								<Select
									className={styles['employee-header-item']}
									options={constantDropdownOptions?.bankData}
									size="large"
									placeholder="Select Bank name"
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								label={renderLabel('Account Number', true)}
								name="accountNumber"
								labelCol={{
									className: styles['form-container-label'],
								}}
								rules={[
									{
										required: true,
										message: 'Account Number is required',
									},
									{
										pattern: /^\d+$/,
										message: 'Account Number must be valid',
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								label={renderLabel(
									'Re-enter Account Number',
									true
								)}
								name="reAccountNumber"
								labelCol={{
									className: styles['form-container-label'],
								}}
								dependencies={['accountNumber']}
								rules={[
									{
										required: true,
										message:
											'Re-entered Account Number is required',
									},
									{
										pattern: /^\d+$/,
										message:
											'Re-entered Account Number must be valid',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (
												!value ||
												getFieldValue(
													'accountNumber'
												) === value
											) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													'Account Numbers do not match'
												)
											);
										},
									}),
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>

						<Col span={24}>
							<Form.Item
								label={renderLabel('IFSC Code', true)}
								name="IFSCCode"
								labelCol={{
									className: styles['form-container-label'],
								}}
								rules={[
									{
										required: true,
										message: 'IFSC Code is required',
									},
									{
										pattern: /^[A-Za-z0-9]+$/,
										message: 'IFSC Code must be valid',
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>
						<Form.Item>
							<div className={styles['buttons']}>
								<Buttons buttons={myButtons} />
							</div>
						</Form.Item>
					</Row>
				</Form>
			</div>
		</div>
	);
};
export default DirectDeposit;


import { FORMDATA } from 'constants/Data';
import { FC, useState } from 'react';
import styles from './index.module.scss';
import { SettingsLayoutProps } from './types';
import PayrollSettingsSidebar from 'components/Global/payrollSettingSidebar';


// Settings page layout
const PayrollSettingsLayout: FC<SettingsLayoutProps> = (props) => {
	const { PayrollSettingsMenuItems } = FORMDATA;
	const { children, onSideBarChange, selectedSidebar } = props;

	const [menuItems, setMenuItems] = useState(PayrollSettingsMenuItems);

	// useEffect(() => {
	// 	let _menuItems = PayrollSettingsMenuItems;
	// 	if (!allowedPermissions.includes('Allow_Company_Setup')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'company-setup'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Pay_Period')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'pay-period'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Statutory_Components')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'statutory-components'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Pay_Group')) {
	// 		_menuItems = _menuItems.filter((item) => item?.key !== 'pay-group');
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Earnings_Code')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'earning-code'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Deduction_Code')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'deduction-code'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Mid-Month_Pay_Setup')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'mid-month-pay'
	// 		);
	// 	}
	// 	// if (!allowedPermissions.includes('Allow_Payroll_History')) {
	// 	// 	_menuItems = _menuItems.filter(
	// 	// 		(item) => item?.key !== 'payroll-history'
	// 	// 	);
	// 	// }
	// 	setMenuItems(_menuItems);
	// }, [PayrollSettingsMenuItems, permissionContext]);

	return (
		<div className={styles['payrollSettings-layout']}>
			<div className={styles['payrollSettings-layout__wrapper']}>
				<PayrollSettingsSidebar
					items={menuItems}
					handleSidebar={onSideBarChange}
					selectedSidebar={selectedSidebar as string}
				/>
				<div className={styles['payrollSettings-layout__body']}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default PayrollSettingsLayout;

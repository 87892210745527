import { Table } from 'antd';
import React from 'react';
import styles from './index.module.scss';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';

type TableProps = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
};

const PayrollHistoryTable = (props: TableProps) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
	} = props;
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Currency"
					dataIndex="currency"
					key="currency"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Pay group"
					dataIndex="payGroup"
					key="payGroup"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Pay month"
					dataIndex="payMonth"
					key="payMonth"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Pay Date"
					dataIndex="payDate"
					key="payDate"
					render={(value: string) =>
						dayjs(value).format('DD/MM/YYYY')
					}
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Created By"
					dataIndex="createdBy"
					key="createdBy"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Action"
					dataIndex="action"
					key="action"
					sorter={false}
					className="bg-white"
				/>
			</Table>
		</div>
	);
};

export default PayrollHistoryTable;

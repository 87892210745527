import { getApi } from 'apis';

const getCompanyDetails = async () => {
	return await getApi('/company/details');
};

const getFinancialYear = async () => {
	return await getApi('/company/financial-year');
};

export const companySetup = {
	getCompanyDetails,
	getFinancialYear,
};

import { Button, Modal, Table, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import { useState } from 'react';
import styles from './index.module.scss';
import './index.scss';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	handleProceedToPayment: () => void;
};

const AddPreviewModal = (props: Props) => {
	const { isOpen, handleCancel, handleProceedToPayment } = props;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	
	const dataSource = [
		{
			id: 1,
			firstName: 'John',
			lastName: 'Doe',
			bankAccountNumber: '12345678',
			sortCode: '00-11-22',
			amount: '1000',
			optionalEmployeeId: 'EMP001',
		},
		{
			id: 2,
			firstName: 'Jane',
			lastName: 'Smith',
			bankAccountNumber: '87654321',
			sortCode: '22-33-44',
			amount: '1500',
			optionalEmployeeId: 'EMP002',
		},
		{
			id: 3,
			firstName: 'Alice',
			lastName: 'Johnson',
			bankAccountNumber: '13579111',
			sortCode: '55-66-77',
			amount: '2000',
			optionalEmployeeId: 'EMP003',
		},
	];

	const handleDownloadBankFile = () => {
		console.log('Downloading bank file...');
	};

	return (
		<Modal
			open={isOpen}
			closable={false}
			onCancel={handleCancel}
			width={800}
			footer={
				<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
					<Button key="back" onClick={handleCancel}>
						Back
					</Button>
					<Button
						key="proceed"
						type="primary"
						onClick={handleProceedToPayment}
						style={{ marginLeft: '10px' }}
					>
						Proceed to Payment
					</Button>
				</div>
			}
		>
			<div className={`${styles['modal']} modal`}>
				<div className={styles['modal-header']}>
					<Title level={4}>
						<span>Preview</span>
					</Title>
					<div>
						<Button
							icon={<DownloadOutlined />}
							type="default"
							onClick={handleDownloadBankFile}
							style={{ marginRight: '10px' }}
						>
							Bank File
						</Button>
						<Button
							icon={<CloseOutlined />}
							onClick={handleCancel}
						/>
					</div>
				</div>
				<div
					className={styles['modal-body']}
					style={{ marginBottom: 0 }}
				>
					<Table
						dataSource={dataSource}
						rowKey="id"
						pagination={false}
						style={{ marginTop: 10 }}
					>
						<Column
							title="Employee Name"
							key="employeeName"
							className="bg-white"
							render={(text, record: any) =>
								`${record.firstName} ${record.lastName}`
							}
						/>
						<Column
							title="Bank Account Number"
							dataIndex="bankAccountNumber"
							key="bankAccountNumber"
							className="bg-white"
						/>
						<Column
							title="Sort Code"
							dataIndex="sortCode"
							key="sortCode"
							className="bg-white"
						/>
						<Column
							title="Amount"
							dataIndex="amount"
							key="amount"
							className="bg-white"
						/>
						<Column
							title="Employee Code"
							dataIndex="optionalEmployeeId"
							key="optionalEmployeeId"
							className="bg-white"
						/>
					</Table>
				</div>
			</div>
		</Modal>
	);
};

export default AddPreviewModal;
